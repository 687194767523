import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ModalPopup } from './common/Modal';
import colors from '../config/colors';
import {
  Button,
  Grid,
  Container,
  Icon,
  Image,
  Transition,
  div,
  Modal,
  List,
  Item,
} from 'semantic-ui-react';
import { ageChecked } from '../actions/authActions';
import BlogRecentPosts from '../components/Blog/BlogRecents';
import { Parallax } from 'react-scroll-parallax';

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tooYoung: false,
      loadingBlogPosts: true,
      posts: [],
      width: window.innerWidth,
    };
  }

  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }
  componentDidMount() {
    this.loadPosts();
    window.scrollTo(0, 0);
    if (this.props.isOfAge) {
      return null;
    } else {
      this.props.ageChecked(false);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  loadPosts() {
    //TODO wp rest api custom endpoint to fetch only data needed from the api
    let postUrl =
      'https://www.cannections.ca/blog/wp-json/wp/v2/posts?per_page=3';
    fetch(postUrl)
      .then((data) => data.json())
      .then((data) => {
        this.setState(
          {
            posts: data,
            loadingBlogPosts: false,
          },
          () => {
            // console.log(this.state.posts);
          }
        );
      });
  }
  // componentDidUpdate() {
  //   console.log(this.props.isOfAge)
  // }

  renderAgeModal = () => {
    let isOfAge = this.props.isOfAge;

    if (isOfAge) {
      return null;
    } else {
      return (
        <Modal size={'small'} open={true}>
          <Modal.Header>Confirm Age</Modal.Header>
          <Modal.Content>
            <p>Please confirm that you're at least 19 years of age.</p>
            {!this.state.tooYoung ? null : (
              <p style={{ marginTop: -10, fontWeight: 600 }}>
                Dang! You're not old enough to use Cannections...
              </p>
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button
              // negative
              onClick={() => {
                this.setState({ tooYoung: true });
              }}
            >
              I am under 19
            </Button>
            <Button
              positive
              icon="checkmark"
              labelPosition="right"
              content="I am old enough"
              onClick={() => {
                this.props.ageChecked(true);
              }}
            />
          </Modal.Actions>
        </Modal>
      );
    }
  };

  render() {
    const { width } = this.state;
    const isMobile = width <= 768;

    return (
      <div style={{ position: 'relative' }}>
        {/* BANNER */}
        {/* <div className="bannerTop">
          <p>Now available on the App Store.</p>
        </div> */}


        {/* <Transition
          visible={true}
          animation="fade"
          transitionOnMount
          duration={1400}
        > */}
        {/* <Image
          id="homeHeaderNetworkBg"
          src="../top-right-network.png"
          alt="background image"
          style={{}}
        /> */}
        {/* </Transition> */}
        <Container
          style={{ zIndex: 999999999999999999999, position: 'relative' }}
        >
          <Grid
            className="middle aligned"
            columns="equal"
            stackable
            id="header"
          >
            {this.renderAgeModal()}

            <Grid.Column id="headerText">
              <Transition
                visible={true}
                animation="fade up"
                transitionOnMount
                duration={1000}
              >
                <div>
                  <h1 style={{ color: colors.skizzuGreen }}>
                    <span style={{ fontWeight: 200, color: colors.blackText }}>
                      Connecting
                    </span>{' '}
                    Cannabis
                  </h1>
                  {/* <h2>A fresh, social experience for your cannabis circle</h2> */}
                  <h2>A social platform for the cannabis community</h2>
                </div>
              </Transition>

              <Transition
                visible={true}
                animation="fade up"
                transitionOnMount
                duration={1000}
              >
                <div>
                  {/* <ModalPopup
                    trigger={
                      <Button
                        animated
                        size="large"
                        style={{
                          backgroundColor: colors.skizzuGreen,
                          color: colors.white,
                          marginRight: 10,
                          borderRadius: 50,
                          // marginBottom: 50,
                          marginTop: 50,
                        }}
                      >
                        <Button.Content visible>
                          Download
                        </Button.Content>
                        <Button.Content hidden>
                          <Icon name="apple" />
                        </Button.Content>
                      </Button>
                    }
                  /> */}

                  <a
                    href="https://apps.apple.com/us/app/cannections/id1465185228"
                    className="item"
                  >
                    {' '}
                    <Button
                      animated
                      size="large"
                      style={{
                        backgroundColor: colors.skizzuGreen,
                        color: colors.white,
                        marginRight: 10,
                        borderRadius: 50,
                        // marginBottom: 50,
                        marginTop: 50,
                      }}
                    >
                      <Button.Content visible>Download</Button.Content>
                      <Button.Content hidden>
                        <Icon name="apple" />
                      </Button.Content>
                    </Button>
                  </a>

                  <h2
                    style={{
                      fontSize: 16,
                      marginTop: 10,
                      marginRight: 20,
                      marginBottom: 50,
                      opacity: 0.8,
                    }}
                  >
                    {/* Only <b>500</b> spots available! */}
                    {/* We just launched! */}
                  </h2>
                </div>
              </Transition>
            </Grid.Column>

            <Grid.Column width={7} id="headImg">
              <Parallax
                translateY={[-10, 10]}
                // translateX={['-40px', '0px']}
                scale={[1.2, 0.8]}
                rotate={[-6, 6]}
              >
                <div>
                  <Transition
                    visible={true}
                    animation="fade up"
                    transitionOnMount
                    duration={1200}
                  >
                    <div style={{ marginTop: 30 }}>
                      <Image
                        src="../mock-home-head1.png"
                        alt="Cannections App Preview"
                      />
                    </div>
                  </Transition>
                </div>
              </Parallax>
            </Grid.Column>
          </Grid>
          <Grid
            columns="equal"
            stackable
            className="wrapperHome"
            style={{ marginBottom: 50 }}
          >
            {/* <Grid.Row columns={1} style={{display: 'flex', alignItems: 'center', textAlign: 'left', margin: '2em' }}>
              <Grid.Column width={8}>
                 <h2>What is Cannections?</h2>
                <p style={{ }}>
                Cannections is a mobile app which connects people to a variety of marijuana brands, dispensaries, and other cannabis 
                users together all on one platform. 
                </p>
              </Grid.Column>
            </Grid.Row> */}

            <Grid.Row
              centered
              columns={2}
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: 120,
              }}
            >
              {!isMobile ? (
                <Grid.Column>
                  <Parallax translateY={[5, -5]}>
                    <Image src="../pass-j.jpg" style={{ borderRadius: 4 }} />
                  </Parallax>
                </Grid.Column>
              ) : null}
              <Grid.Column width={8}>
                <h2>Get Cannected</h2>
                {/* <h2>The only weed app you need</h2> */}
                <p>
                  {/* Cannections is a mobile app which connects people to a variety
                  of marijuana strains, dispensaries, brands, and other cannabis
                  users together all on one platform. */}
                  Get "cannected" with other cannabis users, headshops,
                  dispensaries, services, flower, and so much more like never
                  before.
                  <br />
                  <br />
                  This social and informative weed app is built from the ground
                  up for the cannabis community, with love from members of the
                  cannabis community.
                </p>
                <a
                  href="https://apps.apple.com/us/app/cannections/id1465185228"
                  className="item"
                >
                  <Button
                    size="medium"
                    // style={styles.ctaButton}
                    style={{
                      backgroundColor: colors.skizzuGreen,
                      color: colors.white,
                      marginRight: 10,
                      borderRadius: 50,
                      // marginBottom: 50,
                      marginTop: 10,
                    }}
                  >
                    Cannect
                  </Button>
                </a>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row
              centered
              columns={2}
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: 100,
              }}
            >
              <Grid.Column width={8}>
                <h2>Introducing Indigo</h2>
                {/* <h2>The only weed app you need</h2> */}
                <p>
                  Indigo is your friendly and knowledgeable cannabis AI companion, always eager to offer expert insights on cannabis strains, products, and emerging trends.
                  <br />
                  <br />
                  With a wealth of expertise spanning every facet of cannabis consumption, Indigo takes pride in staying at the forefront of the cannabis universe.
                </p>
                <a
                  href="https://apps.apple.com/us/app/cannections/id1465185228"
                  className="item"
                >
                  <Button
                    size="medium"
                    // style={styles.ctaButton}
                    style={{
                      backgroundColor: colors.skizzuGreen,
                      color: colors.white,
                      marginRight: 10,
                      borderRadius: 50,
                      // marginBottom: 50,
                      marginTop: 10,
                    }}
                  >
                    Get started
                  </Button>
                </a>
              </Grid.Column>
              {!isMobile ? (
                <Grid.Column>
                  <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: '' }}>
                    <Parallax translateY={[-60, 60]} style={{ position: 'absolute', top: -10, left: 105, }}>
                      <Image src="../Asset 1.png" style={{ width: 85 }} />
                    </Parallax>
                    <Image src="../Asset 2.png" style={{ position: 'absolute', top: 20, right: 90, zIndex: 9999, width: 80 }} />
                    <Image src="../Asset 3.png" style={{ position: 'absolute', top: 150, left: 80, width: 50 }} />
                    <Image src="../Asset 4.png" style={{ position: 'absolute', top: 280, left: 120, zIndex: 9999, width: 60 }} />
                    <Parallax translateY={[40, -40]} style={{ position: 'absolute', top: 240, right: 90, }}>
                      <Image src="../Asset 6.png" style={{ width: 80 }} />
                    </Parallax>

                    <Parallax translateY={[-10, 10]}>
                      <Image src="../indingo.PNG" style={{ borderRadius: 400, width: '100%', maxWidth: 350 }} />
                    </Parallax>
                  </div>
                </Grid.Column>
              ) : null}
            </Grid.Row>

            <div style={{ width: '100%', textAlign: 'center', marginTop: 65}}>
              <h2 style={{ margin: 0, marginBottom: 10 }}>Explore Cannabis Culture </h2>
              <p style={{ }}>A hub for sharing and exploring a variety of cannabis-related content. </p>
            </div>

            <Grid.Row className="tabBoxes" columns={3}>
              <Grid.Column>
                <div className="tabBox">
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <i
                        class="fas fa-users"
                        style={{
                          color: colors.skizzuGreen,
                          fontSize: 24,
                          marginTop: -2,
                        }}
                      />
                      <h3>Cannect</h3>
                    </div>
                    <p>
                      Enhance your app experience by connecting with friends, local dispensaries, headshops, and other cannabis enthusiasts. Join our cannabis social network and elevate your journey.
                    </p>
                  </div>
                  <a
                    href="https://apps.apple.com/us/app/cannections/id1465185228"
                    className="item"
                  >
                    <Button size="small" style={styles.ctaButton}>
                      Get started
                    </Button>
                  </a>
                </div>
              </Grid.Column>
              <Grid.Column>
                <div className="tabBox">
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <i
                        class="fas fa-cannabis"
                        style={{
                          color: colors.skizzuGreen,
                          fontSize: 24,
                          marginTop: -2,
                        }}
                      />
                      <h3>Strain Finder</h3>
                    </div>
                    <p>
                      Explore and uncover new cannabis strains using our dynamic strain finder. Find out what's on offer at nearby dispensaries, bookmark your favorite strains, share reviews, and showcase your cannabis to friends and the community.
                    </p>
                  </div>
                  {/* <Link to="/Strains" className="item">
                    <Button size="small" style={styles.ctaButton}>
                      Try the Web Beta
                    </Button>
                  </Link> */}
                  <a
                    href="https://apps.apple.com/us/app/cannections/id1465185228"
                    className="item"
                  >
                    <Button size="small" style={styles.ctaButton}>
                      Get started
                    </Button>
                  </a>
                </div>
              </Grid.Column>
              <Grid.Column>
                <div className="tabBox">
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <i
                        class="fas fa-clone"
                        style={{
                          color: colors.skizzuGreen,
                          fontSize: 24,
                          marginTop: -2,
                        }}
                      />
                      <h3>Explore</h3>
                    </div>
                    <p>
                      Stay updated on the latest activities of your connections, favorite shops, or dispensaries through your personalized feed. Whether it's an expertly rolled joint, a new product launch, or fresh stock at your preferred dispensary, you'll be the first to know with real-time updates.
                    </p>
                  </div>
                  <a
                    href="https://apps.apple.com/us/app/cannections/id1465185228"
                    className="item"
                  >
                    <Button size="small" style={styles.ctaButton}>
                      Get started
                    </Button>
                  </a>
                </div>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="tabBoxes duo" columns={2} style={{}}>
              {/* <Grid.Column>
                <div className="tabBox">
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <i
                        class="fas fa-store"
                        style={{
                          color: colors.skizzuGreen,
                          fontSize: 24,
                          marginTop: -2,
                        }}
                      />
                      <h3>Dispensaries</h3>
                    </div>
                    <p>
                      Check out dispensaries that are connected with us! Our
                      goal is to make sure we have the best platform built for
                      dispensaries to show off not only their product, but THEIR
                      brand.
                    </p>
                  </div>
                  <a
                    href="https://apps.apple.com/us/app/cannections/id1465185228"
                    className="item"
                  >
                    <Button size="small" style={styles.ctaButton}>
                      Get started
                    </Button>
                  </a>
                </div>
              </Grid.Column> */}
              <Grid.Column style={{ marginTop: -30 }}>
                <div className="tabBoxAlt">
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <i
                        class="fas fa-tags"
                        style={{
                          color: colors.skizzuGreen,
                          fontSize: 24,
                          marginTop: -2,
                        }}
                      />
                      <h3>Headshops and Dispensaries</h3>
                    </div>
                    <p>
                      See what your favourite headshops, services or
                      dispensaries are up to. Whether it's a new product or some
                      freshly stocked bud, it will show up in real time on your
                      feed.
                    </p>
                    <a
                      href="https://apps.apple.com/us/app/cannections/id1465185228"
                      className="item"
                    >
                      <Button style={styles.ctaButton}>Download now</Button>
                    </a>
                    <p style={{ marginTop: 30 }}>
                      <b> Are you a business? </b>
                      Sign up you business for free to connect with cannabis
                      users in your area.
                    </p>
                    <Link to="/brand" className="item">
                      <div
                        style={{
                          marginTop: -5,
                          // marginBottom: 30,
                          fontSize: 18,
                          fontWeight: 600,
                          textDecoration: 'underline',
                        }}
                      >
                        <List.Item as="a">
                          Request your access code now!
                        </List.Item>
                      </div>
                    </Link>
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>

            {/* <Grid.Row centered columns={2} style={{display: 'flex', alignItems: 'center', marginTop: 10 }}>
              <Grid.Column width={7}>
                  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 16 }}>

                      <h2 style={{ marginTop: 0, marginLeft: 0 }}>Connect with your favourite dispensaries</h2>
                   </div>
                  <p style={{ marginBottom: 40 }}>
                      See your favourite local Dispensary's menu in real time on their profile page or in their store. Place orders at ease and connect with them to stay up to date with new product or bud!
                      <br/><br/>
                      <b>Do you own a licenced cannabis dispensary?</b> Do you wish to get started with a dispensary account ASAP on Cannections to connect with local cannabis users in your City?
                  </p>

                  <Link to="/dispensary" className="item">
                    <Button animated size='big' style={{ backgroundColor: colors.skizzuGreen, color: colors.white, marginRight: 10, borderRadius: 50 }}>
                      <Button.Content visible>Level up your dispensary today</Button.Content>
                      <Button.Content hidden>
                        <Icon name='arrow right' />
                      </Button.Content>
                    </Button>
                    </Link>

                </Grid.Column>
                <Grid.Column>
                  <Image src='../get-budding-h3PWUqxpP_0-unsplash.jpg' style={{ borderRadius: 4, marginTop: 20 }}/>
                </Grid.Column>
              </Grid.Row> */}
            {/* <Grid.Column width={4} tablet={8} computer={4}>
                  <div className='tabBox'>
                      <div>
                      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <i 
                            class='fas fa-store'
                            style={{ color: colors.secondaryGreen, fontSize: 30 }}
                          /> 
                          <h2>Dispensaries</h2>
                      </div>
                      <p>
                          Find dispensaries near you using Cannections to see their menu on the app or in their dispensary on a tablet display (if they choose to). Browse their product and place orders for pick up at ease.

                      </p>
                      </div>
                      <Link to="/Strains" className="item">
                        <Button size='small' style={styles.ctaButton}>Get started</Button>
                       </Link>
                  </div>
              </Grid.Column> */}
            {/* 
              <Grid.Row centered columns={2} style={{display: 'flex', alignItems: 'center', margin: '4em 0 3em 0'}}>
                <Grid.Column width={6}>
                    <Image src="../grou-mock-2.png" style={{ width: '90%', margin: '0 5% 1em 5%' }} />
                  </Grid.Column>
                <Grid.Column width={8}>
                <h2>Finally, an exclusive place for your cannabis content</h2>
                  <p>
                      Use our interactive Strain Finder to find the best bud for you. See what strains are available in your region,
                      see how they look and get a quick insight on the weed you're about to smoke.
                      <br></br>
                      <br></br>
                      What are you waiting for? Download Cannections from the get go!
                  </p>
                  <Link to="/Strains" className="item">
                        <Button size='small' style={{
                             color: colors.white, 
                             backgroundColor: colors.skizzuGreen, 
                             borderRadius: 50,
                        }}>Get started</Button>
                       </Link>
                </Grid.Column>
            </Grid.Row> */}
            {/* <div style={{ marginTop: 120, marginBottom: 60, width: '100%' }}>
              <h2 style={{ textAlign: 'center' }}>Latest Blog Posts</h2>
            </div>

            <BlogRecentPosts posts={this.state.posts} /> */}
          </Grid>
        </Container>
        <Image
          src="../top-left-network.png"
          alt="background image"
          style={{
            position: 'absolute',
            bottom: -50,
            left: 0,
            height: 'auto',
            width: 450,
            opacity: 0.7,
            zIndex: -9,
          }}
        />
      </div>
    );
  }
}

const styles = {
  ctaButtonOG: {
    //  maxWidth: 150,
    color: colors.white,
    backgroundColor: colors.skizzuGreen,
    borderRadius: 50,
  },
  ctaButton: {
    //  maxWidth: 150,
    color: colors.white,
    backgroundColor: colors.skizzuGreen,
    borderRadius: 50,
  },
};

function mapStateToProps(state) {
  return {
    isOfAge: state.isOfAge,
  };
}

export default connect(
  mapStateToProps,
  {
    ageChecked,
  }
)(Home);
