import React, { Component } from 'react';
import StrainList from './StrainList';
import colors from '../config/colors';
import {
  Grid,
  Container,
  Transition,
} from 'semantic-ui-react';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
        width: window.innerWidth,
      }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  componentWillMount(){
    window.addEventListener('resize', this.handleWindowSizeChange);
  }
  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };
  
  render() {
    return (
      <div style={{ backgroundColor: colors.lightBg }}>
          <Grid className='middle aligned' columns='equal' stackable id="headerStrainList">
            <Container>
            <Grid.Column>
              <Transition visible={true} animation='fade' transitionOnMount duration={500}>
                <div>
                  <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                    <h1 style={{ textAlign: 'left', fontSize: this.state.width <= 768 ? 44 : 54, margin: 0, color: colors.skizzuGreen }}><span style={{ fontWeight: 100, color: colors.blackText }}>Strain</span> Finder</h1>
                    <span style={{ fontSize: 14, color: colors.white, backgroundColor: colors.blue, borderRadius: 90, padding: 4, paddingRight: 12, paddingLeft: 12, marginLeft: 22, marginTop: 12, fontWeight: 600}}>Beta</span>
                  </div>
                   <h2 style={{ textAlign: 'left', fontWeight: 200, marginTop: 4, marginLeft: '3px', color: colors.blackText }}>Get educated on a variety of cannabis strains and find the perfect bud for <span style={{  color: colors.skizzuGreen, fontWeight: 600 }}>you</span>  </h2>
                </div>
              </Transition>
            </Grid.Column>
            </Container>

        </Grid>

        <div style={{ zIndex: 99, position: 'relative' }}>

          <Grid columns='equal' stackable style={{ marginTop: 13 }}>
          
          <div style={{ backgroundColor: colors.white, width: '100%', height: 216, position: 'absolute', display: this.state.width <= 768 ? 'block' : 'none'  }}/>
              <Grid.Column className='strainContainFinder'>
                <StrainList />
              </Grid.Column>

          </Grid>


        </div>
        
      </div>

    );
  }
}


export default Dashboard;
