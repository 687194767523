import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
  selectStrain,
  fetchStrains,
  searchStrains,
  filterByType,
  filterByEffect,
  filterByMedical,
  unmountStrains,
} from '../actions/strainActions';
import colors from '../config/colors';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import {WithRouter} from '../components/WithRouter';

import {
  Button,
  Image,
  Grid,
  Container,
  Transition,
  Dropdown,
} from 'semantic-ui-react';

class StrainList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searching: '',
      width: window.innerWidth,
      loadingMore: false,
      filterValue: null,
      searchFilterText: null,
    };
  }

  componentDidMount() {
    // if (!this.props.strains) {
    this.fetchStrains();
    // }
  }
  componentWillMount() {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentDidUpdate(prevProps) {
    if (this.props.strains !== prevProps.strains) {
      this.setState({
        loadingMore: false,
        // loading: this.props.cameFromDetails ? true : false,
      });
    }
  }

  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  fetchStrains() {
    //this is okay because we are keeping scroll position, but if the user loads a lot of strains it can potentially cost performance on website? worth testing...
    if (this.props.strains.length > 1) {
      return null;
    } else {
      this.props.fetchStrains();
    }
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  handleScroll = (event) => {
    this.setState({
      scrollY: window.scrollY,
    });
  };

  loadMore() {
    // this.setState({
    //   offset: this.state.offset + 50 }, () => {
    //     this.props.fetchStrains(this.state.offset);
    //   });
    const { group } = this.state;
    const { lastKey } = this.props;
    const currentStrains = this.props.strains;
    this.setState({ loadingMore: true });

    if (typeof lastKey === 'undefined') {
      this.setState({ loadingMore: false });
      return null;
    }

    if (group === 'type') {
      this.props.filterByType(this.state.filterValue, currentStrains, lastKey);
    } else if (group === 'effects') {
      this.props.filterByEffect(
        this.state.filterValue,
        currentStrains,
        lastKey
      );
    } else if (group === 'condition') {
      this.props.filterByMedical(
        this.state.filterValue,
        currentStrains,
        lastKey
      );
    } else {
      this.props.fetchStrains(currentStrains, lastKey);
    }
  }

  itemLeaf = (strain) => {
    const { Sativa, Indica } = strain;
    if (Sativa) {
      return (
        <img
          className="ui small image"
          style={{ width: 30 }}
          src="../sativa.png"
          alt="Sativa"
        />
      );
    } else if (Indica) {
      return (
        <img
          className="ui small image"
          style={{ width: 30 }}
          src="../indica.png"
          alt="Indica"
        />
      );
    } else {
      return (
        <img
          className="ui small image"
          style={{ width: 30 }}
          src="../hybrid.png"
          alt="Hybrid"
        />
      );
    }
  };

  renderList = () => {
    const { width } = this.state;
    const isMobile = width <= 500;

    let renderList = this.state.searchFilterText
      ? this.props.searchedStrain
      : this.props.strains;
    return renderList.map((strain) => {
      const { Hybrid, Sativa, Indica } = strain;

      if (isMobile) {
        return (
          <div
            className="item"
            style={{
              borderLeft: !strain.description ? '4px solid red' : '4px solid',
              borderLeftColor: Indica
                ? colors.purple
                : Sativa
                ? colors.orange
                : colors.skizzuGreen,
              borderBottom: '1px solid #fefefe',
              backgroundColor: colors.white,
              padding: '20px 10px',
            }}
            id="mobileListItem"
            key={strain.key}
          >
            <div className="right floated content">
              <button
                className="ui button editBtn"
                onClick={() => this.props.selectStrain(strain, this.props.navigate)}
              >
                View
              </button>
            </div>
            <div
              className="content"
              style={{
                fontSize: 14,
                marginLeft: 4,
                fontWeight: 900,
                color: colors.blackText,
              }}
            >
              {strain.title}
            </div>
            <div
              className="description"
              style={{
                fontSize: 12,
                marginTop: 4,
                marginLeft: 4,
                color: colors.blackLight,
              }}
            >
              {Hybrid ? 'Hybrid' : Sativa ? 'Sativa' : 'Indica'}
            </div>
          </div>
        );
      } else {
        //NEW UI FOR LIST ITEM TABLET PLUS
        return (
          <div
            onClick={() => this.props.selectStrain(strain.key, this.props.navigate)}
            id="itemTabletPlus"
            style={{
              borderRadius: 10,
              backgroundColor: colors.white,
              padding: 10,
              float: 'left',
              width: width < 1024 ? '50%' : '33.33%',
              height: 165,
              marginBottom: 8,
            }}
            key={strain.key}
          >
            <div style={{ position: 'absolute', top: 15, left: 15 }}>
              {this.itemLeaf(strain)}
            </div>
            <div style={{ position: 'absolute', bottom: 12, left: 18 }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                }}
              >
                <div
                  className="content"
                  style={{
                    fontSize: 16,
                    fontWeight: 800,
                    color: colors.blackText,
                    marginRight: 6,
                  }}
                >
                  {strain.title}
                </div>
                <div style={{ fontSize: 12, fontWeight: '600', marginTop: 2 }}>
                  {strain.b ? `by: ${strain.b}` : null}
                </div>
              </div>
              <div
                className="description"
                style={{
                  fontSize: 12,
                  marginTop: 3,
                  color: colors.blackLight,
                }}
              >
                {Hybrid ? 'Hybrid' : Sativa ? 'Sativa' : 'Indica'}
              </div>
            </div>
          </div>
        );
      }
    });
  };

  handleChange = (e, { value }) => {
    // e.persist();
    // console.log(e.target.getAttribute('group'));
    this.props.unmountStrains();
    this.searchInput.value = '';
    const group = e.target.getAttribute('group');
    this.setState(
      {
        filterValue: value,
        group: group,
        loadingMore: true,
        searchFilterText: null,
      },
      () => {
        if (group === 'type') {
          this.props.filterByType(this.state.filterValue, null, null);
        } else if (group === 'effect') {
          this.props.filterByEffect(this.state.filterValue, null, null);
        } else if (group === 'condition') {
          this.props.filterByMedical(this.state.filterValue, null, null);
        } else if (group === null) {
          this.props.fetchStrains();
        }
      }
    );
  };

  renderStrainList() {
    if (
      this.props.searchedStrain.length === 0 &&
      this.state.searchFilterText !== null
    ) {
      return (
        <Container id="strainListEmpty">
          <h3 style={{ marginBottom: 30 }}>
            Nothing found for: {this.state.searchFilterText}
          </h3>
          <Link to="/contact" className="item">
            <Button size="small" style={styles.ctaButton}>
              REQUEST THIS STRAIN
            </Button>
          </Link>
          <p style={{ margin: '20px 0 20px 0' }}>
            We will add it for you within 24 hours if it exists
          </p>
        </Container>
      );
    } else {
      return (
        <Container id="strainList">
          <h3 style={{ marginLeft: 10, marginTop: -10, marginBottom: 40 }}>
            {this.state.searchFilterText
              ? this.state.searchFilterText
              : this.state.filterValue || 'Browsing A-Z'}
          </h3>
          <div className="ui divided list">{this.renderList()}</div>
        </Container>
      );
    }
  }

  renderScrollUpBtn() {
    if (this.state.scrollY > 1024 && this.state.width > 1024) {
      return (
        <Transition
          visible={true}
          animation="zoom"
          transitionOnMount
          duration={500}
        >
          <Button
            onClick={() => {
              $('html, body').animate({ scrollTop: 0 }, 500);
            }}
            id="toTopButton"
          >
            <i
              className={'fas fa-chevron-up'}
              style={{ color: colors.skizzuGreen, fontSize: 18 }}
            />
          </Button>
        </Transition>
      );
    } else {
      return null;
    }
  }

  render() {
    const dropdownItems = [
      <Dropdown.Header
        key={1}
        // icon='tags'
        content="type"
      />,
      {
        key: 'Sativa',
        text: 'Sativa',
        value: 'Sativa',
        group: 'type',
      },
      {
        key: 'Indica',
        text: 'Indica',
        value: 'Indica',
        group: 'type',
      },
      {
        key: 'Hybrid',
        text: 'Hybrid',
        value: 'Hybrid',
        group: 'type',
      },
      <Dropdown.Header
        key={2}
        content="Effect"
      />,
      {
        key: 'Aroused',
        text: 'Aroused',
        value: 'Aroused',
        group: 'effect',
      },
      {
        key: 'Body High',
        text: 'Body High',
        value: 'Body High',
        group: 'effect',
      },
      {
        key: 'Calming',
        text: 'Calming',
        value: 'Calming',
        group: 'effect',
      },
      {
        key: 'Creative',
        text: 'Creative',
        value: 'Creative',
        group: 'effect',
      },
      {
        key: 'Energetic',
        text: 'Energetic',
        value: 'Energetic',
        group: 'effect',
      },
      {
        key: 'Energizing',
        text: 'Energizing',
        value: 'Energizing',
        group: 'effect',
      },
      {
        key: 'Euphoria',
        text: 'Euphoria',
        value: 'Euphoria',
        group: 'effect',
      },
      {
        key: 'Euphoric',
        text: 'Euphoric',
        value: 'Euphoric',
        group: 'effect',
      },
      {
        key: 'Focus',
        text: 'Focus',
        value: 'Focus',
        group: 'effect',
      },
      {
        key: 'Focused',
        text: 'Focused',
        value: 'Focused',
        group: 'effect',
      },
      {
        key: 'Giggly',
        text: 'Giggly',
        value: 'Giggly',
        group: 'effect',
      },
      {
        key: 'Happy',
        text: 'Happy',
        value: 'Happy',
        group: 'effect',
      },
      {
        key: 'Hungry',
        text: 'Hungry',
        value: 'Hungry',
        group: 'effect',
      },
      {
        key: 'Mellow',
        text: 'Mellow',
        value: 'Mellow',
        group: 'effect',
      },
      {
        key: 'Motivation',
        text: 'Motivation',
        value: 'Motivation',
        group: 'effect',
      },
      {
        key: 'Relaxing',
        text: 'Relaxing',
        value: 'Relaxing',
        group: 'effect',
      },
      {
        key: 'Sleepy',
        text: 'Sleepy',
        value: 'Sleepy',
        group: 'effect',
      },
      {
        key: 'Long Lasting',
        text: 'Long Lasting',
        value: 'Long Lasting',
        group: 'effect',
      },
      {
        key: 'Sociable',
        text: 'Sociable',
        value: 'Sociable',
        group: 'effect',
      },
      {
        key: 'Tingly',
        text: 'Tingly',
        value: 'Tingly',
        group: 'effect',
      },
      {
        key: 'Uplifting',
        text: 'Uplifting',
        value: 'Uplifting',
        group: 'effect',
      },
      <Dropdown.Header
        key={3}
        content="Condition"
      />,
      {
        key: 'ADD',
        text: 'ADD',
        value: 'ADD',
        group: 'condition',
      },
      {
        key: 'ADHD',
        text: 'ADHD',
        value: 'ADHD',
        group: 'condition',
      },
      {
        key: 'Anxiety',
        text: 'Anxiety',
        value: 'Anxiety',
        group: 'condition',
      },
      {
        key: 'Alzheimers',
        text: 'Alzheimers',
        value: 'Alzheimers',
        group: 'condition',
      },
      {
        key: 'Anorexia',
        text: 'Anorexia',
        value: 'Anorexia',
        group: 'condition',
      },
      {
        key: 'Appetite Loss',
        text: 'Appetite Loss',
        value: 'Appetite Loss',
        group: 'condition',
      },
      {
        key: 'Arthritis',
        text: 'Arthritis',
        value: 'Arthritis',
        group: 'condition',
      },
      {
        key: 'Asthma',
        text: 'Asthma',
        value: 'Asthma',
        group: 'condition',
      },
      {
        key: 'Bipolar Disorder',
        text: 'Bipolar Disorder',
        value: 'Bipolar Disorder',
        group: 'condition',
      },
      {
        key: 'Cancer',
        text: 'Cancer',
        value: 'Cancer',
        group: 'condition',
      },
      {
        key: 'Chronic Pain',
        text: 'Chronic Pain',
        value: 'Chronic Pain',
        group: 'condition',
      },
      {
        key: 'Cramps',
        text: 'Cramps',
        value: 'Cramps',
        group: 'condition',
      },
      {
        key: 'Depression',
        text: 'Depression',
        value: 'Depression',
        group: 'condition',
      },
      {
        key: 'Epilepsy',
        text: 'Epilepsy',
        value: 'Epilepsy',
        group: 'condition',
      },
      {
        key: 'Eye Pressure',
        text: 'Eye Pressure',
        value: 'Eye Pressure',
        group: 'condition',
      },
      {
        key: 'Fatigue',
        text: 'Fatigue',
        value: 'Fatigue',
        group: 'condition',
      },
      {
        key: 'Fibromyalgia',
        text: 'Fibromyalgia',
        value: 'Fibromyalgia',
        group: 'condition',
      },
      {
        key: 'Gastrointestinal Disorder',
        text: 'Gastrointestinal Disorder',
        value: 'Gastrointestinal Disorder',
        group: 'condition',
      },
      {
        key: 'Headaches',
        text: 'Headaches',
        value: 'Headaches',
        group: 'condition',
      },
      {
        key: 'Hyperactivity',
        text: 'Hyperactivity',
        value: 'Hyperactivity',
        group: 'condition',
      },
      {
        key: 'Hypertension',
        text: 'Hypertension',
        value: 'Hypertension',
        group: 'condition',
      },
      {
        key: 'Inflammation',
        text: 'Inflammation',
        value: 'Inflammation',
        group: 'condition',
      },
      {
        key: 'Insomnia',
        text: 'Insomnia',
        value: 'Insomnia',
        group: 'condition',
      },
      {
        key: 'Irregular Bowel Movements',
        text: 'Irregular Bowel Movements',
        value: 'Irregular Bowel Movements',
        group: 'condition',
      },
      {
        key: 'Irritability',
        text: 'Irritability',
        value: 'Irritability',
        group: 'condition',
      },
      {
        key: 'Loss of Appetite',
        text: 'Loss of Appetite',
        value: 'Loss of Appetite',
        group: 'condition',
      },
      {
        key: 'Migraines',
        text: 'Migraines',
        value: 'Migraines',
        group: 'condition',
      },
      {
        key: 'Mood Swings',
        text: 'Mood Swings',
        value: 'Mood Swings',
        group: 'condition',
      },
      {
        key: 'Muscle Spasms',
        text: 'Muscle Spasms',
        value: 'Muscle Spasms',
        group: 'condition',
      },
      {
        key: 'Nausea',
        text: 'Nausea',
        value: 'Nausea',
        group: 'condition',
      },
      {
        key: 'Neuropathy',
        text: 'Neuropathy',
        value: 'Neuropathy',
        group: 'condition',
      },
      {
        key: 'Nightmares',
        text: 'Nightmares',
        value: 'Nightmares',
        group: 'condition',
      },
      {
        key: 'PMS',
        text: 'PMS',
        value: 'PMS',
        group: 'condition',
      },
      {
        key: 'PTSD',
        text: 'PTSD',
        value: 'PTSD',
        group: 'condition',
      },
      {
        key: 'Pain',
        text: 'Pain',
        value: 'Pain',
        group: 'condition',
      },
      {
        key: 'Spinal Cord Injury',
        text: 'Spinal Cord Injury',
        value: 'Spinal Cord Injury',
        group: 'condition',
      },
      {
        key: 'Stress',
        text: 'Stress',
        value: 'Stress',
        group: 'condition',
      },
      {
        key: 'Tremors',
        text: 'Tremors',
        value: 'Tremors',
        group: 'condition',
      },
    ];

    if (this.props.strains.length === 0) {
      return (
        <div
          className="ui segment center"
          style={{ height: 400, marginTop: -13, borderWidth: 0 }}
        >
          <div className="ui active inverted dimmer">
            <div className="ui text loader">Loading Strains...</div>
          </div>
        </div>
      );
    }

    return (
      <div id="strainsListContainer">
        <div
          style={{
            backgroundColor: colors.white,
            borderBottom: '1px solid',
            borderColor: colors.borderLight,
          }}
        >
          {/* <h3 style={{ marginLeft: 10 }}>Strain Finder</h3> */}
          <Container>
            <Grid
              className="middle aligned"
              verticalAlign="middle"
              columns="equal"
              stackable
              id="filterContainer"
            >
              <Grid.Column width={10}>
                <div className="ui icon input searchInput">
                  <input
                    type="text"
                    placeholder="Search by name..."
                    id="strainListSearchInput"
                    onChange={(event) => {
                      this.setState({
                        searchFilterText: event.target.value,
                        filterValue: null,
                      });
                      this.props.searchStrains(
                        event.target.value.toLowerCase()
                      );
                    }}
                    ref={(el) => (this.searchInput = el)}
                  />
                  <i className="search icon" />
                </div>
              </Grid.Column>

              <Grid.Column width={6}>
                <Dropdown
                  // search
                  loading={this.state.loadingMore ? true : false}
                  placeholder="Filter strains by..."
                  fluid
                  selection
                  options={dropdownItems}
                  clearable
                  id="strainFilter"
                  onChange={this.handleChange}
                  value={this.state.filterValue}
                />
              </Grid.Column>
            </Grid>
          </Container>
          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
              alignItems: 'center',
              paddingBottom: 20,
              justifyContent: 'flex-end',
              maxWidth: 1200,
              margin: 'auto',
            }}
          >
            <i
              className={'fas fa-mobile-alt'}
              id="phoneIconStrainFinder"
              style={{ color: colors.blue }}
            />
            <p
              id="strainFinderDownloadAppTextHead"
              style={{ color: colors.blackText, marginLeft: 10 }}
            >
              {' '}
              <a href="https://apps.apple.com/us/app/cannections/id1465185228">
                Download
              </a>{' '}
              our app for the full experience.
            </p>
          </div>
        </div>


        {this.renderStrainList()}


        <div id="loadMorebtnContainer">
          <Button
            loading={this.state.loadingMore ? true : false}
            className=""
            onClick={() => {
              this.loadMore();
            }}
            id="loadMorebtn"
            style={{
              display:
                this.props.searchedStrain.length === 0 &&
                this.state.searchFilterText !== null
                  ? 'none'
                  : 'block',
            }}
          >
            View More
          </Button>
        </div>
        
        {this.renderScrollUpBtn()}
      </div>
    );
  }
}

const styles = {
  ctaButton: {
    //  maxWidth: 150,
    color: colors.white,
    backgroundColor: colors.secondaryGreen,
    borderRadius: 50,
  },
};
const mapStateToProps = (state) => {
  const strains = _.map(state.strains.strains, (val, uid) => {
    return { ...val, uid };
  });
  const searchedStrain = _.map(state.searchingForStrain, (val, uid) => {
    return { ...val, uid };
  });
  return {
    strains,
    searchedStrain,
    activeStrain: state.selectedStrain || null,
    lastKey: state.strains.lastKey,
  };
};

export default connect(
  mapStateToProps,
  {
    selectStrain,
    fetchStrains,
    searchStrains,
    filterByType,
    filterByEffect,
    filterByMedical,
    unmountStrains,
  }
)(WithRouter(StrainList));
