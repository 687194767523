import React, { Component } from 'react';
import colors from '../config/colors';
import { Link, NavLink } from 'react-router-dom';
// import { Redirect } from 'react-router'
import { Button, Grid, Container, Icon, Image } from 'semantic-ui-react';
class Header extends Component {
  render() {
    return (
      <div
        className="footer"
        style={{
          // marginTop: 60,
          padding: 60,
          backgroundColor: colors.white,
          boxShadow: '0 0px 2px 0px rgba(0,0,0,.1)',
          justifyContent: 'center',
          display: 'flex',

          flexDirection: 'column',
        }}
      >
        <Link to="/" className="item">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Image
              src="../hybrid.png"
              style={{ width: 30, marginBottom: 15 }}
            />
            <p style={{ textAlign: 'center', color: colors.blackText }}>
              &copy; Cannections Inc. {new Date().getFullYear()}
            </p>
          </div>
        </Link>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'center',
            margin: 10,
          }}
        >
          <NavLink to="/terms" exact className="item textLi">
            Terms
          </NavLink>

          <NavLink to="/privacy" exact className="item textLi">
            Privacy
          </NavLink>

          <NavLink to="/contact" className="item textLi">
            Contact
          </NavLink>

          {/* <NavLink to="/dispensary" className="item textLi">
              Dispensary Services
            </NavLink> */}

          <NavLink to="/brand" className="item textLi">
            Shops
          </NavLink>
        </div>
      </div>
    );
  }
}

export default Header;
